<template>
  <div>
    <v-data-table
      v-model="selected"
      show-select
      :headers="headers"
      :items="tbData"
      :single-expand="singleExpand"
      :search="search"
      :loading="loadingTb"
      loading-text="Sedang Memuat Data..."
      :expanded.sync="expanded"
      item-key="saktiVersionId"
    >

      <template v-slot:item.noRow="{ item }">
        {{tbData.map(function(x) {return x.saktiVersionId; }).indexOf(item.saktiVersionId)+1}}
      </template>

      <template v-slot:item.totalAnggaran="{item}">
        <div class="p-2 text-right">
          {{G_numFormat(item.totalAnggaran)}}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu
          transition="slide-x-transition"
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#fafafa"
              class="btn-table"
              fab
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list
            flat
            dense
            elevation="1"
          >
            <!-- <v-subheader>Action</v-subheader> -->
            <v-list-item-group
              v-if="isCurrentApprover"
              color="primary"
            >
              <v-list-item @click="sendApproval(item.saktiVersionId, item.statusApprovalId)">
                <v-list-item-icon class="mr-3">
                  <v-icon small>mdi-send</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Kirim</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <router-link :to="{ name: 'adk_kirim_approval_detail', params: { id: item.saktiVersionId, paramSatker: item.satker.satkerNm, paramNamaVersi: item.saktiVersionNm, paramTotalAnggaran: item.totalAnggaran }}">
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-icon class="mr-3">
                    <v-icon small>mdi-send</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Detil</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </router-link>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

  </div>
</template>
<script>
export default {
  data() {
    return {
      expanded: [],
      singleExpand: false,
      selected: [],
      headers: [
        { text: "No", value: "noRow", sortable: false, width: "1%" },
        { text: "Satker", value: "satker.satkerNm" },
        { text: "Nama Versi", value: "saktiVersionNm" },
        { text: "Total Anggaran", value: "totalAnggaran", align: "right" },
        { text: "Status", value: "statusApproval.statusApprovalNm" },
        { text: "Next Approver", value: "nextApprover" },
        { text: "Tanggal", value: "crtdt" },
        { text: "Act", value: "actions", sortable: false, width: "2%" },
      ],
    };
  },
  mounted() {},
  computed: {
    isCurrentApprover: function () {
      console.log(this.getUser?.userGroup);
      if (
        this.getUser?.userGroup != undefined &&
        this.getUser?.userGroup != null
      ) {
        if ("satker" == this.getUser.userGroup) return true;
        else return false;
      } else {
        return false;
      }
    },
  },
  methods: {
    getTable() {
      this.$emit("getTable");
    },
    sendApproval(id, statusApprovalId) {
      this.$emit("sendApproval", id, statusApprovalId);
    },
    sendApprovalSelected() {
      this.$emit("sendApprovalSelected", this.selected);
    },
    resetApprovalSelection() {
      this.selected = [];
    },
    reset(id) {
      this.$emit("reset", id);
    },
  },

  props: ["tbData", "search", "loadingTb"],
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"loading":_vm.loadingTb,"loading-text":"Sedang Memuat Data...","expanded":_vm.expanded,"item-key":"saktiVersionId"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.noRow",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tbData.map(function(x) {return x.saktiVersionId; }).indexOf(item.saktiVersionId)+1)+" ")]}},{key:"item.totalAnggaran",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2 text-right"},[_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalAnggaran))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-table",attrs:{"color":"#fafafa","fab":"","depressed":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"flat":"","dense":"","elevation":"1"}},[(_vm.isCurrentApprover)?_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":function($event){return _vm.sendApproval(item.saktiVersionId, item.statusApprovalId)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Kirim")])],1)],1)],1):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'adk_kirim_approval_detail', params: { id: item.saktiVersionId, paramSatker: item.satker.satkerNm, paramNamaVersi: item.saktiVersionNm, paramTotalAnggaran: item.totalAnggaran }}}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Detil")])],1)],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
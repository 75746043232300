import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      search: "",
      loadingTb: false,
      tbData: [],
      listStatker: [],
      listEvent: [],
    };
  },
  computed: {
    isCurrentApprover: function() {
      console.log(this.getUser?.userGroup);
      if (
        this.getUser?.userGroup != undefined &&
        this.getUser?.userGroup != null
      ) {
        if ("satker" == this.getUser.userGroup) return true;
        else return false;
      } else {
        return false;
      }
    },
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable();

    this.G_GetRef('satker').then(data => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {
        this.$refs.satker.setValue(this.getUser.kdsatker)

      }else{
        this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
      }
       
    });

    // this.G_GetAny("sakti/event").then((res) => {
    //   let resData = res.data.content;

    //   let listData = [];
    //   let arrSub = {};

    //   resData.forEach((v) => {
    //     arrSub = {};

    //     arrSub["text"] = v.saktiEventNm;
    //     arrSub["value"] = v.saktiEventId;

    //     listData.push(arrSub);
    //   });

    //   this.listEvent = listData;
    // });
  },
  components: {
    myTable,
  },
  methods: {
    getTable() {
      let filter = [];

      if (this.$refs.satker && this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter.push("kdsatker=" + this.$refs.satker.getValue());
        }
      }

      // if (this.$refs.event && this.$refs.event.getValue()) {
      //   if (this.$refs.event.getValue() != "all") {
      //     filter.push("saktiEventId=" + this.$refs.event.getValue());
      //   }
      // }

      filter.push("saktiEventId=" + this.$route.query.eventId);

      if (filter.length > 0) {
        filter = "?" + filter.join("&");
      }

      console.log(filter);

      this.loadingTb = true;
      this.G_GetAny("rkakl/versi" + filter).then((res) => {
        console.log(res);
        this.loadingTb = false;
        this.tbData = res.data;
      });
    },
    async sendApproval(saktiVersionId, statusApprovalId) {
      console.log(saktiVersionId);
      console.log(statusApprovalId);

      if (statusApprovalId == 1) {
        this.$refs.notif.info(
          "Data sudah dikirim. Mohon menunggu proses Approval"
        );
        return;
      }

      if (statusApprovalId == 2) {
        this.$refs.notif.info("Data sudah di Approve");
        return;
      }

      let judul = "Monitoring Persetujuan";
      let subJudul = "Apakah anda akan mengirim versi ini untuk Approval?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();

        let listSaktiVersionId = [];
        listSaktiVersionId.push(saktiVersionId);

        this.G_PostAnyCustom("rkakl/versi/approval/", {
          listSaktiVersionId,
        })
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.$refs.confirm.close();
              this.$refs.approvalTable.resetApprovalSelection();
              this.getTable();
              this.$refs.notif.success(`Berhasil mengirim Approval Versi`);
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },

    async kirimPilihanData(selectedRow) {
      console.log("terpilih");
      console.log(selectedRow);

      if (selectedRow.length > 0) {
        let judul = "Monitoring Persetujuan";
        let subJudul = "Apakah anda akan mengirim versi ini untuk Approval?";

        if (await this.$refs.confirm.open(judul, subJudul)) {
          this.$refs.confirm.loading();

          let listSaktiVersionId = [];
          selectedRow.forEach((v) => {
            if (v.statusApprovalId == 0 || v.statusApprovalId == 3)
              listSaktiVersionId.push(v.saktiVersionId);
          });

          this.G_PostAnyCustom("rkakl/versi/approval/", {
            listSaktiVersionId,
          })
            .then((res) => {
              console.log(res);
              if (!res.status) {
                this.$refs.confirm.close();
                this.$refs.approvalTable.resetApprovalSelection();
                this.getTable();
                this.$refs.notif.success(`Berhasil mengirim Approval Versi`);
              } else {
                this.$refs.notif.warning("Terjadi Kesalahan");
              }
            })
            .finally(() => {
              this.$refs.confirm.loadingEnd();
            });
        }
      } else {
        this.$refs.notif.info("Pilih versi yang akan dikirim");
      }
    },

    async kirimSemuaDataApproval() {
      let judul = "Monitoring Persetujuan";
      let subJudul = "Apakah anda akan mengirim Approval untuk semua Data?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();

        this.G_PostAnyCustom("rkakl/versi/approval/all/", {})
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.$refs.confirm.close();
              this.$refs.approvalTable.resetApprovalSelection();
              this.getTable();
              this.$refs.notif.success(
                `Berhasil mengirim Semua Approval Versi`
              );
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },
  },
};
